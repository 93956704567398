<template>
    <div class="max_div">
        <div class="top_div">
            <div class="block_div">
                <el-row :gutter="20">
                    <el-col :span="4">
                        <div class="block">
                            <div class="data_div">
                                总数
                            </div>
                            <div class="data_div">
                                {{this.report.num}}
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div class="block">
                            <div class="data_div">
                                已绑用户
                            </div>
                            <div class="data_div">
                                {{this.report.num1}}
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div class="block">
                            <div class="data_div">
                                未绑设备
                            </div>
                            <div class="data_div">
                                {{this.report.num2}}
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div class="input_div">
                <el-input v-model="inputData" placeholder="输入SN码" :size="tabsize" style="width:18%"></el-input>
                <el-button type="success" :size="tabsize" style="margin-left:1%" icon="el-icon-search" @click="getInList">查询</el-button>
                <el-button type="primary" :size="tabsize" style="margin-left:1%" icon="el-icon-setting" @click="jumpPage('returnGoods')">发货管理</el-button>
                <el-button type="primary" :size="tabsize" style="margin-left:1%" icon="el-icon-setting"  @click="jumpPage('market')" v-if="market" >市场管理</el-button>
                <el-button type="primary" :size="tabsize" style="margin-left:1%" icon="el-icon-setting" @click="dialogClick(2)">入库管理</el-button>
            </div>
        </div>
        <div class="center_div">
            <el-table  v-if="marketData" :data="tableData" border stripe :size="tabsize" style="width:100%;overflow:auto">
                    <el-table-column label="孩子姓名" prop='code' align='center'>
                        <template slot-scope="scope">
                            <span>{{ scope.row.cname }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="手机号" prop='code' align='center' >
                        <template slot-scope="scope">
                            <span>{{ scope.row.phone}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="城市" prop='code' align='center'>
                        <template slot-scope="scope">
                            <span>{{ scope.row.address }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="sn码所属" prop='code' align='center'>
                        <template slot-scope="scope">
                            <span>{{ scope.row.pname }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="设备sn" prop='code' align='center'>
                        <template slot-scope="scope">
                            <span>{{ scope.row.sn }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="服务商" prop='code' align='center'>
                        <template slot-scope="scope">
                            <span>{{ scope.row.pname1 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="服务商码" prop='code' align='center'>
                        <template slot-scope="scope">
                            <span>{{ scope.row.code1 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="绑定时间" prop='code' align='center'>
                        <template slot-scope="scope">
                            <span>{{ scope.row.bindtime }}</span>
                        </template>
                    </el-table-column>
            </el-table>

            <el-table  v-if="!marketData" :data="tableData" border stripe :size="tabsize" style="width:100%;overflow:auto" >+
                    <el-table-column label="产品类型" prop='code' align='center'>
                        <template slot-scope="scope">
                            <span>{{ scope.row.devtype | typeFilter(that)}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="型号" prop='code' align='center' >
                        <template slot-scope="scope">
                            <span>{{ scope.row.devm}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="规格" prop='code' align='center'>
                        <template slot-scope="scope">
                            <span>{{ scope.row.devspe }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="SN码" prop='code' align='center'>
                        <template slot-scope="scope">
                            <span>{{ scope.row.sn }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="状态" prop='code' align='center'>
                        <template slot-scope="scope">
                            <span>{{ scope.row.bindtime == 0 ?  '/':'已售' }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="绑定时间" prop='code' align='center'>
                        <template slot-scope="scope">
                            <span>{{ scope.row.bindtime |timestampToTime}}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="入库时间" prop='code' align='center'>
                        <template slot-scope="scope">
                            <span>{{ scope.row.intime | timestampToTime}}</span>
                        </template>
                    </el-table-column>
            
                    <!-- <el-table-column label="操作" prop='code' align='center' width="200">
                        <template slot-scope="scope">
                            <div class="button_list_div">
                                <el-button :size="tabsize" icon="el-icon-edit"  type="primary" @click="dialogClick(3,scope.row)">查看</el-button>
                            </div> 
                        </template>
                    </el-table-column> -->
            </el-table>
        </div>
        
        <div class="footer_div">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
                    :page-sizes="[10, 20, 30, 40]" :page-size="pageSize"  layout="total, sizes, prev, pager, next, jumper"
                    :total="totalRow">
            </el-pagination>
        </div>

        <dialogList @addTableDataOk="addTableDataOk($event)" :dialogType="dialogType" :title="dialogTitle" :width="dialogWidth"></dialogList>
    </div>
</template>

<script>
import dialogList from './dialogList.vue'
    export default {
        components:{
            dialogList
        },
        // 监听尺寸按钮变化 改变表格大小
      computed: {
          tabsize() {
              this.$setTableHeightMax()
              return this.$store.state.tabsize
          }
      },
      created(){
        this.getDictInfo()
      },
      mounted(){
         this.getReport()
         this.getInList()
      },
      data(){
        return{
            sort: "",
            order: "desc",
            currentPage: 1, //默认显示第一页
            pageSize: 10, //默认每页显示10条
            totalNum: "", //总页数
            topDataList:[],
            dialogTitle:'',
            dialogWidth:'',
            dialogType:'',
            inputData:'',
            tableData:[],
            report:[],
            marketData: undefined,
            currentPage: 1, //默认显示第一页
            pageSize: 10, //默认每页显示10条
            totalNum: '', //总页数
            order: '',
            sort: '',
            totalRow:1,
            dictData:[],
            that:this,
            market:false
        }
      },

      filters:{
            typeFilter:function(type,that){
                for(let i=0;i<that.dictData.length;i++){
                    if(type == that.dictData[i].k){
                        return that.dictData[i].v
                    }
                }
                return '未知'
            }
      },

      methods:{

        getReport(){
            this.$api.equipment.getReport({

            }).then((res) =>{
                this.report = res.obj
            }).catch((error)=>{
                console.log(error);
            })
        },
        getInList(){
            this.$api.equipment.getInList({
                'jPage.pageNum': this.currentPage, //当前页码
                'jPage.pageSize': this.pageSize, //当前页面大小
                'jPage.sort': this.sort, //排序字段
                'jPage.order': this.order, //排序方式
                'sn':this.inputData
            }).then((res) =>{
                console.log(res);
                if(res.obj.qd_lvl == 1){
                    this.market = true
                }
                this.tableData = res.obj.obj.list
                this.totalRow = res.obj.obj.totalRow
            }).catch((error)=>{
                console.log(error);
            })
        },
        getDictInfo(){
            this.$api.vipuser.getinfo({
                dtype:'qd_devtype'
            }).then((res) =>{
                this.dictData = res.obj
            }).catch((error)=>{
                console.log(error);
            })
        },
        addTableDataOk(type){
            this.dialogType = 0
            if(type == -1){
                return
            }
            this.getInList()
        },
        dialogClick(type){
            this.dialogType = 0
            if(type == 1){
                this.dialogTitle = '批量导入发货清单'
                this.dialogWidth = '550px',
                this.dialogType = type
            }else if(type == 2){
                this.dialogTitle = '批量入库清单'
                this.dialogWidth = '550px',
                this.dialogType = type
            }
        },
        jumpPage(type){
            if(type == 'returnGoods'){
                this.$router.push({
                    path: '/returnGoods',
                    query: {}
                })
            }
            if(type == 'market'){
                this.$router.push({
                    path: '/market',
                    query: {}
                })
            }
        },
        // 分页大小
        handleSizeChange(val) {
            this.loading = true
            this.pageSize = val
            this.currentPage = 1 // 选择分页大小后跳转第一页
            this.getInList()
        },
        // 第几页
        handleCurrentChange(val) {
            this.loading = true
            this.currentPage = val
            this.getInList()
        },
        closeDia(){
            this.dialogType = 0
        }
      }
    }
</script>

<style scoped>
    .max_div{
        position: relative;
        width: 98%;
        height: 98%;
        margin: 1%;
    }
    .block_div{
        width: 100%;
        height: 8vh;
    }
    .input_div{
        margin-top: 1%;
        align-items: center;
    }
    .block{
        width: 100%;
        height: 100%;
        background-color: #fff;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .el-row{
        width: 100%;
        height: 100%;
    }

    .el-col{
        height: 100%;
    }
    .data_div{
        text-align: center;
        width: 50%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .el-table{
        height: 100%;
    }
    /** 解决el-table放大之后多一条线*/
    .el-table::before{
        z-index: inherit;
    }
    .center_div{
        margin-top: 1%;
    }
   

   

</style>